<template>
  <div class="h5-container">
    <div class="main">
      <div class="title font-20">
        工业工程师资格证书
      </div>
      <div class="contain">
        <div class="mainContent">
          <div class="line1">
            <div class="label font-12">姓名：</div>
            <el-input
              placeholder="请输入您的姓名"
              class="value font-14"
              v-model="name"
              clearable>
            </el-input>
          </div>
          <div class="line1">
            <div class="label font-12">身份证号：</div>
            <el-input
              placeholder="请输入您的身份证号"
              class="value font-14"
              v-model="idNo"
              clearable>
            </el-input>
          </div>
        </div>
        <div class="btnCon font-15">
          <button class="btn font-15" @click="getDetail()">查询</button>
        </div>
      </div>
      <div class="icp-no">
        <a href="http://beian.miit.gov.cn/" class="font-14" target="_blank">备案号：津ICP备2022002937号</a>
      </div>
        <div class="policy-no">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010102000999" class="font-14"
        ><img src="../assets/police.jpg" style="float: left" />
        <p>津公网安备 12010102000999号</p></a
      >
    </div>
    </div>
  </div>
</template>

<script>
import * as API from '@/api/apiService.js'
export default {
  name: 'Search',
  data () {
    return {
      idNo: '',
      name: ''
    }
  },

  methods: {
    getDetail () {
      if (!this.name) {
        this.$message({
          message: '请填写姓名',
          type: 'warning'
        })
        return false
      } else if (!this.idNo) {
        this.$message({
          message: '请填写身份证号',
          type: 'warning'
        })
        return false
      }
      if (!this.IsIdCardNo(this.idNo.trim())) {
        this.$message({
          message: '请输入正确的身份证号',
          type: 'warning'
        })
        return false
      }
      const params = {
        userName: this.name.trim(),
        userIdno: this.idNo.trim()
      }
      API.getDetail(params).then(res => {
        if (res) {
          this.$router.push({ name: 'CertificateInfo', query: { data: encodeURIComponent(JSON.stringify(res)) } })
        }
      // }).catch(res => {
      //   this.$message.error(res.response.data.message)
      })
    },
    IsIdCardNo (idCardNo) {
      if (!idCardNo) { return false }
      var reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
      if (reg.test(idCardNo)) {
        var v = idCardNo
        var checkData = {
          0: 1,
          1: 0,
          2: 'X',
          3: 9,
          4: 8,
          5: 7,
          6: 6,
          7: 5,
          8: 4,
          9: 3,
          10: 2
        }
        if (parseInt(idCardNo.length) === 18) {
          var sum = v[0] * 7 + v[1] * 9 + v[2] * 10 + v[3] * 5 + v[4] * 8 + v[5] * 4 +
                v[6] * 2 + v[7] * 1 + v[8] * 6 + v[9] * 3 + v[10] * 7 +
                v[11] * 9 + v[12] * 10 + v[13] * 5 + v[14] * 8 + v[15] * 4 + v[16] * 2
          var remain = sum % 11
          var check = checkData[remain]
          if (check.toString() === v[17].toUpperCase()) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else { return false }
    }
  }
}
</script>

<style scoped>
.title{
  margin-left: 0.195rem;
  color: #FFFFFF;
  font-weight: bold;
  padding-top: 0.24rem;
}

.contain{
  margin: 0 auto;
  margin-top: 0.24rem;
  width: 3.35rem;
  height: 2.88rem;
  background: #FFFFFF;
  border-radius: 0.08rem;
}
.mainContent{
  font-size: 0.14rem;
}

.line1{
  width: 100%;
  padding-top: 0.195rem;
  padding-left: 0.2rem;
  position: relative;
}
.label{
  color:rgba(27, 44, 88, 1);
  font-weight: bold;
  margin-bottom: 0.08rem
;
}
.value{
  width: 2.95rem;
  margin:0 auto;
  height: 0.5rem;
  line-height: 0.5rem;
}
.main{
  background: url(../assets/bg_all.png) no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.icon-close{
  background: url(../assets/close.png) no-repeat;
  background-size: 0.24rem;
  width: 0.32rem;
  position: absolute;
  height:0.32rem ;
  right: 0.40rem;
  top: 0.57rem;
}
.btn{
  height: 0.44rem;
  width: 100%;
  cursor: pointer;
  background: #185ACC ;
  border: none;
  border-radius: 0.04rem;
  color: #FFFFFF;
}

.btnCon{
  cursor: pointer;
  width: 88%;
  margin: 0 auto;
  margin-top: 0.3rem;

}
.el-button.is-round{
  border-radius: 4px;
  font-size: 15px;
}
.icp-no {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 50px;
  left: 0;
}
.icp-no a {
  color: #ffffff;
  display: inline-block;
}

.policy-no {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.policy-no a {
  color: #ffffff;
  display: inline-block;
}
.policy-no p {
  float: left;
  height: 20px;
  line-height: 20px;
  margin: 0px 0px 0px 5px;
  color: #ffffff;
}
</style>
