import axios from 'axios'
import Vue from 'vue'
import { getToken } from '@/utils/auth'
import { Loading } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  async config => {
    // do something before request is sent
    const headers = {
      'Content-Type': 'application/json'
    }
    config.headers = { ...config.headers, ...headers }
    config.headers.token = getToken()
    try {
      if (config.loading !== false) {
        config.loading = Loading.service({
          customClass: 'api-loading-container',
          fullscreen: true,
          lock: true,
          background: 'rgba(0,0,0,0.5)',
          spinner: 'el-icon-loading'
        })
      }
    } catch (e) {
      await closeLoading({ config })
      window.console && console.error('error occur before axios request.', e)
      config.cancelToken = new axios.CancelToken((cancel) => cancel('Request cancelled.'))
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

const closeLoading = (target) => {
  if (target.config === undefined) {
    window.console && console.error('error where closeLoading:' + target)
  }
  const { loading } = target.config
  if (!loading) return true
  target.config.loading.close()
}
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async response => {
    await closeLoading(response)
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== undefined) {
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  async error => {
    await closeLoading(error)
    const res = error.response || {}
    const data = res.data || {}
    console.log('err' + error) // for debug
    if (error.config.message !== false) {
      const netmessage = error.code === 'ECONNABORTED' ? '连接超时，请稍候再试！' : '网络错误，请稍后再试！'
      Vue.prototype.$message.error(data.message || netmessage)
    }
    return Promise.reject(error)
  }
)

export default service
