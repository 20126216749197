<template>
  <div class="main">
    <div class="header">
      <div class="headerText">
        <div class="titleText">工业工程师认证通过人员名单</div>
        <div class="logout" @click="logout"><el-divider direction="vertical"></el-divider><img src="../assets/logout.png" class="imgout"/>注销</div>
      </div>
    </div>
    <div class="fistline">
      <el-input
        v-model="keyword"
        placeholder="请输入查询信息"
        class="input-with-select"
        @keydown.enter.native="search()"
      >
        <el-select slot="prepend" v-model="keywordType" class="selected-keywordType">
          <el-option
            v-for="item in keywordTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-input>
      专业技术资格名称：
       <el-select v-model="typeName" class="selected-invoice-type" placeholder="请选择">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
      <el-button class="queryButton" @click="search()">查询</el-button>
      <div style="float:right;width:18%;margin-top: 30px">
          <el-upload class="upload-demo"
              ref="upload"
              action="#"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              :limit="1"
              :show-file-list="false"
              :before-upload="beforeUpload">
            <el-button  class="exportData" icon="el-icon-bottom-right">导入</el-button>
          </el-upload>
          <el-button class="addButton" icon="el-icon-plus" @click="add()">新建</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <div class="table-head">
        <div class="title userName">
          姓名
        </div>
        <div class="title idNo">
          身份证号
        </div>
        <div class="title cername">
          专业技术资格名称
        </div>
        <div class="title cerNo">
          证书编码
        </div>
        <div class="title time">
          有效期至
        </div>
        <div class="title operator">
          操作
        </div>
      </div>
      <div class="table-body">
        <div v-for="(item , index) in list" :key="index" class="table-item">
          <div class="second-line">
            <div class="item-data userName">
              <div class="center-multiple-line">
                <div class="line-item">
                      {{ item.userName }}
                </div>
              </div>
            </div>
            <div class="item-data idNo">
              <div class="center-multiple-line">
                  <div class="line-item">
                  {{ item.userIdno }}
                </div>
              </div>
            </div>
             <div class="item-data cername">
              <div class="center-multiple-line">
                  <div class="line-item">
                  {{ item.typeName }}
                </div>
              </div>
            </div>
            <div class="item-data cerNo">
              <div class="center-multiple-line">
                <div class="line-item">
                  {{ item.certificateNo }}
                </div>
              </div>
            </div>
            <div class="item-data time">
              <div class="center-multiple-line">
                <div class="line-item">
                  {{ item.deadlineTime }}
                </div>
              </div>
            </div>
            <div class="item-operator operator">
              <div class="center-multiple-line">
                <div class="line-item">
                  <div class="operator-view">
                    <span class="spacewidth"><img class="img-point" src="../assets/detail.png" @click="detailitem(item.userName,item.userIdno)"/></span>
                    <span class="spacewidth"><img class="img-point" src="../assets/edit.png" @click="edititem(item.userName,item.userIdno)"/></span>
                    <span class="spacewidth"><img class="img-point" src="../assets/delete.png" @click="deleteitem(item.id)"/></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination :total="total" :current-page.sync="currentPage" :page-size="pageSize" @current-change="page_change" />

    <div class="showrule" v-show="isShow">
      <div class="policy" id="policy">
       <div class="notice-title">
          <div class="typename pull-left">
            <h2 class="inbk pull-left">{{title}}</h2>
          </div>
          <div class="close-notice pull-right" @click="closeModal">
            <span class="icon-close">×</span>
          </div>
          <div class="clear"></div>
        </div>
      <!--这里是要展示的内容层-->
        <div class="policyContent">
          <div class="addinfo" style="margin-top:16px">
            <div class="spantext">姓名</div>
            <div><el-input v-model="name" placeholder="请输入姓名" :disabled="isDisabled" class="inputclass"></el-input></div>
          </div>
          <div class="addinfo">
            <div class="spantext">身份证号</div>
            <div><el-input v-model="idNo" placeholder="请输入身份证号" :disabled="isDisabled" class="inputclass"></el-input></div>
          </div>
          <div class="addinfo">
            <div class="spantext">证书编码</div>
            <div><el-input v-model="cerNo" placeholder="请输入证书编码" :disabled="isDisabled" class="inputclass"></el-input></div>
          </div>
          <div class="addinfo">
            <div class="spantext">有效期至</div>
            <div>
              <!-- <el-input v-model="deadlineTime" placeholder="请输入证书到期日，格式为：2022年2月" class="inputclass" :disabled="isDisabled"></el-input> -->
              <el-date-picker
                v-model="deadlineTime"
                type="month"
                placeholder="请选择证书有效期"
                format="yyyy 年 M 月"
                value-format="yyyy年M月"
                class="inputclass"
                :disabled="isDisabled">
              </el-date-picker>
            </div>
          </div>
          <div class="addinfo" v-if="isDisabled && fileUrl">
            <div class="spantext">证书</div>
            <div>
              <div><img :src="fileUrl" class="img-file" @click="openFile(fileUrl)"/></div>
            </div>
          </div>
          <div class="addinfo" v-else-if="!isDisabled">
            <div class="spantext">添加证书</div>
            <div  v-if="fileUrl">
              <div><img :src="fileUrl" class="img-file" @click="openFile(fileUrl)"/><img style="margin-left:-0.12rem;" src="../assets/deletefile.png" @click="deleteFile()"/></div>
            </div>
            <div class="uploadimg" v-else>
              <!-- <div><img src="../assets/uploadimg.png" style="margin-right:0.05rem"/>点击<span style="color:rgba(108, 164, 255, 1)">上传证书</span>图片</div> -->
              <van-uploader use-before-read :before-read="beforeRead" multiple = "false">
                <img src="../assets/uploadimg.png" style="margin-right:0.05rem"/>点击<span style="color:rgba(108, 164, 255, 1)">上传证书</span>图片
              </van-uploader>
            </div>
          </div>
        </div>
      <div class="confirm-btn" @click="save()">完成</div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API from '@/api/apiService.js'
import Pagination from '@/components/Pagination'
import * as auth from '../utils/auth'
import * as imageConversion from 'image-conversion'
export default {
  name: 'List',
  components: {
    pagination: Pagination
  },
  data () {
    return {
      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      isShow: false,
      keywordTypeOptions: [
        {
          value: 'userName',
          label: '姓名'
        },
        {
          value: 'userIdno',
          label: '身份证号'
        },
        {
          value: 'certificateNo',
          label: '证书编码'
        }
      ],
      typeOptions: [
        { label: '请选择', value: '' },
        { label: '见习工业工程师', value: '4' },
        { label: '工业工程师', value: '3' }],
      name: '',
      idNo: '',
      cerNo: '',
      keywordType: 'userName',
      keyword: '',
      isDisabled: false,
      fileUrl: '',
      fileList: [],
      operation: '',
      deadlineTime: '',
      id: '',
      title: '',
      typeName: ''
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      var params = {
        curpage: this.currentPage,
        pagesize: this.pageSize,
        userName: '',
        certificateNo: '',
        userIdno: '',
        typeName: this.typeName
      }
      if (this.keywordType === 'userName') {
        params.userName = `${this.keyword}`
      } else if (this.keywordType === 'userIdno') {
        params.userIdno = `${this.keyword}`
      } else if (this.keywordType === 'certificateNo') {
        params.certificateNo = `${this.keyword}`
      }
      API.list(params).then(res => {
        if (res) {
          this.list = res.records
          this.total = res.total
          this.currentPage = res.current
        }
      }).catch(res => {
        if (res.response.data.errorCode) {
          if (res.response.data.errorCode === 'token_error') {
            // this.$message.error(res.response.data.message)
            this.$router.push({ name: 'Login' })
          // } else {
          //   this.$message.error(res.response.data.message)
          }
        }
      })
    },
    search () {
      this.currentPage = 1
      this.getList()
    },
    getDetails (name, no, type) {
      API.getDetail({ userName: name, userIdno: no }).then(res => {
        if (res) {
          this.isShow = true
          if (type === 'detail') {
            this.isDisabled = true
          } else {
            this.isDisabled = false
          }
          this.name = res.data.userName
          this.idNo = res.data.userIdno
          this.cerNo = res.data.certificateNo
          if (res.data.deadlineTime) {
            if (res.data.deadlineTime.indexOf('年') > -1) {
              this.deadlineTime = res.data.deadlineTime
            } else {
              this.deadlineTime = ''
            }
          }
          this.id = res.data.id
          if (res.data.fileUrl) {
            this.fileUrl = res.data.fileUrl
          } else {
            this.fileUrl = ''
          }
        }
      }).catch(res => {
        if (res.response.data.errorCode) {
          if (res.response.data.errorCode === 'token_error') {
            // this.$message.error(res.response.data.message)
            this.$router.push({ name: 'Login' })
          // } else {
          //   this.$message.error(res.response.data.message)
          }
        }
      })
    },
    closeModal () {
      this.isShow = false
      this.isDisabled = false
    },
    logout () {
      auth.removeToken()
      this.$router.push({ name: 'Login' })
    },
    beforeUpload (file) {
      const fileFormData = new FormData()
      fileFormData.append('file', file, file.name) //  注意'file'要和后端统一
      API.importExcel(fileFormData).then(res => {
        this.$message({
          message: '导入成功',
          type: 'success'
        })
        this.getList()
      }).catch(res => {
        if (res.response.data.errorCode) {
          if (res.response.data.errorCode === 'token_error') {
            // this.$message.error(res.response.data.message)
            this.$router.push({ name: 'Login' })
          // } else {
          //   this.$message.error(res.response.data.message)
          }
        }
      })
    },
    beforeRead (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('上传图片只能是 JPG 或 PNG 格式!')
        return false
      }
      // 压缩到100KB,这里的100就是要压缩的大小,可自定义
      imageConversion.compressAccurately(file, 100).then(res => {
        // Blob转file类型
        const resFile = new window.File(
          [res],
          file.name,
          { type: file.type }
        )
        const fileFormData = new FormData()
        fileFormData.append('file', resFile, resFile.name) //  注意'file'要和后端统一
        API.uploadImg(fileFormData).then(response => {
          this.fileUrl = response.url
        // }).catch(res => {
        //   this.$message.error(res.response.data.message)
        })
      })
    },
    page_change (currentPage) {
      this.currentPage = currentPage
      this.getList()
    },
    vertify () {
      if (!this.name) {
        this.$message({
          message: '请填写姓名',
          type: 'warning'
        })
        return false
      } else if (!this.idNo) {
        this.$message({
          message: '请填写身份证号',
          type: 'warning'
        })
        return false
      } else if (!this.IsIdCardNo(this.idNo.trim())) {
        this.$message({
          message: '请输入正确的身份证号',
          type: 'warning'
        })
        return false
      } else if (!this.cerNo) {
        this.$message({
          message: '请填写证书编号',
          type: 'warning'
        })
        return false
      } else if (!this.deadlineTime) {
        this.$message({
          message: '请填写证书到期日',
          type: 'warning'
        })
        return false
      }
      return true
    },
    add () {
      this.name = ''
      this.cerNo = ''
      this.idNo = ''
      this.fileUrl = ''
      this.deadlineTime = ''
      this.isShow = true
      this.operation = 'add'
      this.title = '新建'
    },
    save () {
      if (this.operation === 'detail') {
        this.closeModal()
      } else {
        if (!this.vertify()) {
          return
        }

        var params = {
          userName: this.name,
          userIdno: this.idNo,
          certificateNo: this.cerNo,
          deadlineTime: this.deadlineTime,
          fileUrl: this.fileUrl
        }
        if (this.operation === 'edit') {
          params.id = this.id
        }
        API.save(params).then(res => {
          if (res) {
            this.$message({
              message: '保存成功！',
              type: 'success'
            })
            this.closeModal()
            this.getList()
          }
        }).catch(res => {
          if (res.response.data.errorCode) {
            if (res.response.data.errorCode === 'token_error') {
              // this.$message.error(res.response.data.message)
              this.$router.push({ name: 'Login' })
            // } else {
            //   this.$message.error(res.response.data.message)
            }
          }
        })
      }
    },
    edititem (name, no) {
      this.operation = 'edit'
      this.title = '编辑'
      this.getDetails(name, no, 'edit')
    },
    detailitem (name, no) {
      this.title = '详情'
      this.operation = 'detail'
      this.getDetails(name, no, 'detail')
    },
    deleteitem (id) {
      this.$confirm('确认删除此证书？', '温馨提示').then(() => {
        API.deleteitem({ id }).then(res => {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.getList()
        })
      }).catch(res => {
        if (res === 'cancel') {
          this.$message.warning('取消删除')
        } else {
          if (res.response.data.errorCode) {
            if (res.response.data.errorCode === 'token_error') {
              // this.$message.error(res.response.data.message)
              this.$router.push({ name: 'Login' })
            // } else {
            //   this.$message.error(res.response.data.message)
            }
          }
        }
      })
    },
    deleteFile () {
      this.fileUrl = ''
    },
    openFile (file) {
      window.open(file)
    },
    IsIdCardNo (idCardNo) {
      if (!idCardNo) { return false }
      var reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
      if (reg.test(idCardNo)) {
        var v = idCardNo
        var checkData = {
          0: 1,
          1: 0,
          2: 'X',
          3: 9,
          4: 8,
          5: 7,
          6: 6,
          7: 5,
          8: 4,
          9: 3,
          10: 2
        }
        if (parseInt(idCardNo.length) === 18) {
          var sum = v[0] * 7 + v[1] * 9 + v[2] * 10 + v[3] * 5 + v[4] * 8 + v[5] * 4 +
                v[6] * 2 + v[7] * 1 + v[8] * 6 + v[9] * 3 + v[10] * 7 +
                v[11] * 9 + v[12] * 10 + v[13] * 5 + v[14] * 8 + v[15] * 4 + v[16] * 2
          var remain = sum % 11
          var check = checkData[remain]
          if (check.toString() === v[17].toUpperCase()) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else { return false }
    }
  }
}
</script>
<style scoped>
.main{
  margin: 0 auto;
  background: #FAFCFE;
  min-height: 100vh;
}
.header{
  height: 70px;
  line-height: 70px;
  background: #FFFFFF;
  border-bottom: solid 1px rgba(0, 0, 0, 0.06);
}

.titleText{
  float: left;
  color: rgba(20, 45, 102, 1);
  font-family: Microsoft YaHei UI;
  font-size: 20px;
  font-weight: bold;
}
.el-pagination{
  text-align: right;
  padding-top: 32px;
  width: 1200px;
  margin: 0 auto;
}
.table{
    margin-top: 40px;
    margin:0 auto;
    width:1200px;
    background: #FFFFFF;
    margin-top: 30px;
}
.table-head {
    height: 48px;
    background: rgba(241, 245, 249, 1);
    line-height: 48px;
    text-align: center;
    display: flex;
    color: rgba(93, 105, 122, 1);
    font-size: 14px;
    text-align: left;
}
.table-body {
    color: #000000;
}
.table-item {
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(231, 236, 239, 1);
}

.second-line {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  height: 72px;
  line-height: 72px;
  font-size: 14px;
}

.center-multiple-line{
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height:200%;
  margin-top: 28px;
}
.line-item{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.userName{
  width: 160px;
  min-width:160px;
  text-align: left;
  padding-left: 30px;
}

.operator{
  width: 180px;
  min-width: 180px;
  text-align: left;
  padding-left: 30px;
}

.idNo{
  width:190px;
  min-width: 190px;
  text-align: left;
  padding-left: 30px;
}
.cername{
  width: 180px;
  min-width: 180px;
  text-align: left;
  padding-left: 30px;
}
.cerNo{
  width: 190px;
  min-width: 190px;
  text-align: left;
  padding-left: 30px;
}
.time{
  width: 180px;
  min-width: 180px;
  text-align: left;
  padding-left: 30px;
}
.exportData{
  width: 96px;
  height: 40px;
  border: solid 1px rgba(0, 184, 218, 1);
  background: #FFFFFF;
  color: rgba(24, 90, 204, 1);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  margin-right: 20px;
  align-items: center;
}
.addButton{
  width: 96px;
  height: 40px;
  border: none;
  background: rgba(24, 90, 204, 1);
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  align-items: center;
}
.upload-demo >>> .el-upload {
  display: block;
  float: left;
}
.headerText{
  width: 1200px;
  margin: 0 auto;
}
.logout{
  float: right;
  width: 120px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 1);
}
.imgout{
  margin-right: 6px;
  padding-left: 30px;
}
.fistline{
  width: 1200px;
  margin: 0 auto;
  font-size: 14px;
}
.input-with-select {
  width: 400px;
  height: 40px;
  line-height: 40px;
  margin-right: 24px;
  margin-top: 30px;
}
.selected-invoice-type{
  height: 40px;
  line-height: 40px;
}
.selected-keywordType{
  width: 120px;
  font-family: MicrosoftYaHeiUI;
  border-radius:4px;
  outline: none;
  color: #000;
  font-weight: normal;
}

.queryButton{
  width: 72px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  border: solid 1px #DCDFE6;
  cursor: pointer;
  margin-left: 10px;
  padding:0px;
  line-height: normal;
}
.spacewidth{
  margin-right:14px;
}

.showrule{
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 99;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.policy{
  width: 480px;
  height: 696px;
  padding: 0 30px;
  border-radius: 4px;
  padding-top: 0;
  background: #fff;
}
.spantext{
  color: rgba(102, 102, 102, 1);
  margin-bottom: 14px;
}
.typename{
  margin-top: 23px;
  font-weight: bold;
}
.addinfo{
  margin-top: 25px;
  font-size: 14px;
}
.inbk{
  font-weight: bold;
}
.uploadimg{
  height: 110px;
  border-radius: 4px;
  border: solid 1px rgba(231, 236, 239, 1);
  background: rgba(250, 250, 250, 1);
  text-align: center;
  line-height: 110px;
  cursor: pointer;
}
.policyContent{
  height: 558px;
}
.confirm-btn{
  float: right;
  width: 80px;
  height: 32px;
  line-height: 32px;
  background: rgba(24, 90, 204, 1);
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.img-point{
  cursor: pointer;
}
.img-file{
  width: 81px;
  height: 110px;
  float: left;
  object-fit: cover;
}
.icon-close{
  color:rgb(93, 90, 90) ;
  font-size: 24px;
}
.notice-title{
  height:64px;
}
.pull-left{
  float: left;
  font-size: 18px;
}
.pull-right{
  font-size: 16px;
  float: right;
  margin-top: 16px;
  cursor: pointer;
}
.inputclass{
  height: 40px;
  line-height: 40px;
}
</style>
<style>
.el-message__icon {
  font-size: 16px;
}
.el-select>.el-input{
  height: 40px;
  line-height: 40px;
}
.el-input__inner{
  padding:0 18px !important;
}

.el-date-editor.el-input{
  width: 100% !important;
}

.el-date-editor.el-input> .el-input__prefix{
  display: none !important;
}
.el-divider--vertical{
  height: 3em !important;
}

.el-divider{
  background-color: rgba(0, 0, 0, 0.06) !important;
}
</style>
