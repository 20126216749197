import Cookies from 'js-cookie'

const TokenKey = 'ieeng_token'

export function parseJwt (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  if (process.env.VUE_APP_SECURE_COOKIE === 'true') {
    return Cookies.set(TokenKey, token, { samesite: 'None', secure: true })
  } else {
    return Cookies.set(TokenKey, token)
  }
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}
