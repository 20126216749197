<template>
  <div class="mainPage">
    <div class="mainContent">
      <div class="title">工业工程师资格证书</div>
      <div class="line1">
        <!-- <i class="icon-user"></i> -->
        <div class="labelName">用户名</div>
        <el-input
          v-model="userName"
          placeholder="请输入用户名"
          class="name"
        ></el-input>
      </div>
      <div class="line1" style="margin-top: 23px">
        <div class="labelName">密码</div>
        <el-input
          v-model="password"
          placeholder="请输入密码"
          class="name"
          type="password"
          @keydown.enter.native="login()"
        ></el-input>
      </div>
      <div class="btnCon">
        <button class="btn" @click="login()">确定</button>
      </div>
    </div>
    <div class="icp-no">
      <a href="http://beian.miit.gov.cn/" target="_blank"
        >备案号：津ICP备2022002937号</a
      >
    </div>
    <div class="policy-no">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010102000999"
        ><img src="../assets/police.jpg" style="float: left" />
        <p>津公网安备 12010102000999号</p></a
      >
    </div>
  </div>
</template>

<script>
import * as API from '@/api/apiService.js'
import * as auth from '../utils/auth'
// @ is an alias to /src
export default {
  name: 'Login',
  data () {
    return {
      password: '',
      userName: ''
    }
  },
  methods: {
    login () {
      if (!this.userName) {
        this.$message({
          message: '请填写用户名',
          type: 'warning'
        })
        return false
      } else if (!this.password) {
        this.$message({
          message: '请填写密码',
          type: 'warning'
        })
        return false
      }
      const params = {
        userName: this.userName,
        password: this.password
      }
      API.login(params).then((res) => {
        if (res) {
          auth.setToken(res.token)
          this.$router.push({ name: 'List' })
        }
        // }).catch(res => {
        //   if (res.response.data.errorCode) {
        //     this.$message.error(res.response.data.message)
        //   }
      })
    }
  }
}
</script>

<style scoped>
.mainPage {
  position: relative;
}
.title {
  color: #142d66;
  font-size: 24px;
  font-weight: bold;
  padding-top: 39px;
  padding-left: 41px;
}
.mainContent {
  width: 440px;
  height: 433px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 8px;
}
.bg {
  padding-top: 312px;
}
.line1 {
  font-size: 10px;
  width: 360px;
  margin: 0 auto;
  margin-top: 41px;
}
.labelName {
  margin-bottom: 10px;
  font-size: 14px;
}
.mainPage {
  background: url(../assets/bg2.png) no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.name {
  width: 360px;
  height: 56px;
  border-radius: 8px;
  font-size: 16px;
}
.btnCon {
  font-size: 18px;
  width: 360px;
  height: 56px;
  margin: 0 auto;
  cursor: pointer;
}
.btn {
  width: 360px;
  background: #185acc;
  border: none;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 42px;
  color: #ffffff;
  height: 56px;
  cursor: pointer;
}
.icp-no {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 50px;
  left: 0;
}
.icp-no a {
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
}

.policy-no {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.policy-no a {
  color: #ffffff;
  font-size: 14px;
  display: inline-block;
}
.policy-no p {
  float: left;
  height: 20px;
  line-height: 20px;
  margin: 0px 0px 0px 5px;
  color: #ffffff;
}
</style>
<style>
.el-input__inner {
  height: 100% !important;
  min-height: 100% !important;
}
</style>
