<template>
  <div class="h5-container">
    <div style="height:0.16rem"></div>
    <div class="img-back">
      <div class="mainContent">
          <div class="info">
            <span class="spanname font-10">姓 名</span>
            <div class="divname font-12">{{info.userName}}</div>
          </div>
          <div class="info">
            <span class="spanname font-10">身 份 证 号</span>
            <div class="divname font-12">{{info.userIdno}}</div>
          </div>
          <div class="info">
            <span class="spanname font-10">专 业 技 术 水 平</span>
            <div class="divname font-12">{{info.typeName}}</div>
          </div>
          <div class="info">
            <span class="spanname font-10">证 书 编 号</span>
            <div class="divname font-12">{{info.certificateNo}}</div>
          </div>
          <div class="info">
            <span class="spanname font-10">有 效 期 至</span>
            <div class="divname1 font-12">{{info.deadlineTime}}</div>
            <div class="certificate" v-if="info.fileUrl"><img :src="info.fileUrl" @click="openFile(info.fileUrl)" class="imgcer"/></div>
          </div>
      </div>
    </div>
    <div class="back font-12" @click="goback()">
      返回
    </div>
    <div style="height:0.16rem"></div>
    <div class="showrule" v-show="isShow">
      <div class="policy" id="policy">
      <!--这里是要展示的内容层-->
        <div class="tips font-13" v-if="duesoon">
          您的证书<span class="orange">即将到期</span><br/>请尽快联系考点再注册
        </div>
        <div class="tips font-13" v-if="soon">
          您的证书<span class="red">已过期</span><br/>需重新申请
        </div>
        <div class="confirm-btn font-14" @click="closemodal()">确定</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from 'vant'
import '@vant/touch-emulator'
export default {
  name: 'CertificateInfo',
  data () {
    return {
      info: {},
      isShow: false,
      duesoon: false,
      soon: false,
      name: '',
      idno: ''
    }
  },
  mounted () {
    if (!this.$route.query.data) {
      this.$message({
        message: '暂无数据',
        type: 'warning'
      })
      this.goback()
      return false
    }
    var resData = JSON.parse(decodeURIComponent(this.$route.query.data))
    this.info = resData.data
    if (this.info.deadlineTime) {
      var moment = require('moment')
      moment().format()
      var date = this.info.deadlineTime
      var mouth = moment(date.replace(/年/, '-').replace(/月/, '')).startOf('M').diff(moment().startOf('M'), 'M')
      if (mouth >= 0 && mouth <= 1) {
        this.isShow = true
        this.duesoon = true
        this.soon = false
      } else if (mouth < 0) {
        this.isShow = true
        this.soon = true
        this.duesoon = false
      } else {
        this.isShow = false
        this.soon = false
        this.duesoon = false
      }
    }
  },
  methods: {
    percent2percent25 (URI) {
      if (URI.indexOf('%') > -1) {
        return URI.replace(/%/g, '%25')
      } else {
        return URI
      }
    },
    goback () {
      this.$router.push('/')
    },
    closemodal () {
      this.isShow = false
    },
    openFile (file) {
      ImagePreview([file])
    }
  }
}
</script>
<style scoped>
.h5-container{
  background: rgba(221, 228, 240, 1);
}

.back{
  width: 1.1rem;
  height: 0.36rem;
  line-height: 0.36rem;
  border-radius: 0.18rem;
  background:rgba(24, 90, 204, 1) ;
  color: #FFFFFF;
  text-align: center;
  margin: 0.19rem auto;
  cursor: pointer;
}
.mainContent{
  font-size: 15px;
  width: 100%;
  padding-top: 1.295rem;
}
.info{
  padding-left: 0.72rem;
  height: 0.72rem;
}
.spanname{
  height: 40px;
  line-height: 40px;
  color: rgba(32, 49, 103, 1);
}

.divname{
  width: 72%;
  border-bottom: solid 0.0005rem rgba(102, 102, 102, 1);
  padding-bottom: 0.045rem;
}

.divname1{
  border-bottom: solid 0.0005rem rgba(102, 102, 102, 1);
  padding-bottom: 0.045rem;
  width: 40%;
}
.btn{
  width: 85%;
  margin: 30px;
}
.img-back{
  background: url("../assets/cerback.png") no-repeat;
  width: 3.35rem;
  height: 6.05rem;
  background-size: contain;
  margin: 0 auto;
}
.certificate{
  float: right;
  margin-right: 25%;
  margin-top: -20%;
}
.spacewidth{
  margin-left: 0.1rem;
}

.showrule{
  height: 100%;
  width: 3.85rem;
  background: rgba(0,0,0,0.5);
  z-index: 9999;
  position: fixed;
  top: 0;
}
.policy{
  width: 2rem;
  height: 1.26rem;
  padding: 0 30px;
  border-radius: 0.04rem;
  margin: 0 auto;
  margin-top: 2rem;
  padding-top: 0;
  background: #fff;
  z-index: 99999;
}
.tips{
  text-align: center;
  padding-top: 0.26rem;
}
.confirm-btn{
  text-align: center;
  margin-top: 0.2rem;
  color: rgba(50, 74, 115, 1);
  border-top: solid 0.0005rem rgba(252, 252, 252, 1);
  height: 0.445rem;
  line-height: 0.445rem;
  cursor: pointer;
}

.orange{
  color: rgba(234, 105, 0, 1);
}

.red{
  color: rgba(219, 35, 35, 1);
}

.imgcer{
  width: 0.72rem;
  height: 0.98rem;
  cursor: pointer;
  object-fit: cover;
}
</style>
