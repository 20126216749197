<template>
  <el-pagination
    layout="jumper, slot, prev, next"
    :hide-on-single-page="true"
    :total="total"
    :page-size="pageSize"
    :current-page.sync="myCurrentPage"
    class="ok-pagination"
    @prev-click="onPrevClick"
    @next-click="onNextClick"
    @current-change="onPageChanged"
  >
    <span class="pagination-total-page">共 {{ total }} 条/{{ pageTotal }} 页</span>
  </el-pagination>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    pageSize: { type: Number, default: 10 },
    total: { type: Number, default: 0 },
    currentPage: { type: Number, default: 1 }
  },
  computed: {
    componentKey () {
      return this.total + '-' + this.currentPage + '-'
    },
    myCurrentPage: {
      get () {
        return this.currentPage
      },
      set (p) {
        this.$emit('update:currentPage', p)
      }
    },
    pageTotal () {
      return Math.ceil(this.total / this.pageSize)
    }
  },
  methods: {
    onPageChanged (pageNo) {
      this.$emit('current-change', pageNo)
    },
    onPrevClick (pageNo) {
      this.$emit('prev-click', pageNo)
    },
    onNextClick (pageNo) {
      this.$emit('next-click', pageNo)
    }
  }
}
</script>
<style scoped>
.pagination-total-page {
  color:#606266;
  font-weight: normal;
  margin: 0px 10px 0px 10px;
}
</style>
