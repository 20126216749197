import request from '@/utils/request'

export function getDetail (params) {
  return request({
    method: 'post',
    url: '/certificate/getDetail',
    loading: true,
    data: params
  })
}

export function login (params) {
  return request({
    method: 'post',
    url: '/sys/login',
    loading: true,
    data: params
  })
}

export function list (params) {
  return request({
    method: 'post',
    url: '/certificate/list',
    loading: true,
    data: params
  })
}

export function importExcel (params) {
  return request({
    method: 'post',
    url: '/certificate/importExcel',
    loading: true,
    data: params
  })
}

export function deleteitem (params) {
  return request({
    method: 'post',
    url: '/certificate/delete',
    loading: true,
    data: params
  })
}

export function uploadImg (params) {
  return request({
    method: 'post',
    url: '/certificate/uploadImg',
    loading: true,
    data: params
  })
}
export function save (params) {
  return request({
    method: 'post',
    url: '/certificate/save',
    loading: true,
    data: params
  })
}
